  /* margin-top: 150px; */


  .customer-logo:hover {
    filter: invert(61%) sepia(54%) saturate(4968%) hue-rotate(192deg) brightness(100%) contrast(90%);
  }

  .customer-logo img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 108px;

  }

  .swiper {
    z-index: 0;
  }

  .titl {
    margin-top: 200px;
  }
/* 
  @media screen and (max-width: 768px) {
    .titl {
      display: none;
    }

    .customer-logo:hover {
      filter: none;
    }
  } */