.wrapper {
  max-width: 1920px;
  width: 75%;
  margin: auto;
}

@media screen and (max-width: 1600px) {
  .wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 1440px) {
  .wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 85%;
  }
}