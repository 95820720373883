.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.56);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.overlay iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 82%;
}

@media screen and (max-width: 1042px) {
  .overlay iframe {
    width: 75%;
    height: 56%;
  }
}

@media screen and (max-width: 420px) {
  .overlay iframe {
    width: 95%;
    height: 25%;
  }
}