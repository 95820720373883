.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  padding: 2.2rem;
}

.card-container {
  padding: 30px 56px;

}

.card-container h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 18.75px;
  line-height: 30px;
  color: var(--blackColor);
  margin-top: 20px;
  margin-bottom: 6px;
}

.card-container p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: var(--subTitleColor);
  margin-bottom: 12.5px;
}

.card-container a {
  text-decoration: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--blackColor);
  display: inline-flex;
  transition: var(--transition);

}

.card-container a:hover {
  color: var(--primaryColor);
  transition: var(--transition);
}

.blue-blur {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  transform: translateX(790px);
  overflow-x: visible;

}

.blue-blur-img {
  position: absolute;
}

.waves {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.waves-img {
  position: absolute;
  top: -200px;
}

.plusses {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.plusses-img {
  position: absolute;
  top: 297px;
  left: -100px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.icon-container {
  display: none;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 80px;
}

.icon {
  display: inline-flex;
  background-color: var(--primaryColor);
  padding: 18px;
  border-radius: 50%;
  box-shadow: -3.75px 11.25px 22.5px rgba(15, 137, 255, 0.301961);
}


@media screen and (max-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 2.2rem 0;
  }

  .icon-container {
    display: flex;
  }

  .blue-blur {
    display: none !important;
  }

  .plusses {
    display: none;
  }
}