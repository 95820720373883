:root {
  /* Colors */
  --primaryColor: #4392F1;
  --blackColor: #030314;
  --secondaryColor: #696969;
  --subTitleColor: #70798B;
  /* fonts */
  --primaryFont: Poppins;
  /*transition*/
  --transition: 300ms;
}