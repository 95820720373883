.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  padding: 2.2rem;
}

.card-container {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: var(--transition);
}

.card-container h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--blackColor);
  margin-top: 30px;
  margin-bottom: 10px;
}

.card-container p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: var(--subTitleColor)
}

.card-container:hover {
  background: #fff;
  box-shadow: -15px 30px 60px rgba(22, 22, 22, 0.3);
  border-radius: 16px;
  transition: var(--transition);
  transform: translateY(-17px);

}


.blob-lightgreen {
  background: rgb(29, 226, 207);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(29, 226, 207);
  transform: scale(1);
  animation: pulse-lightgreen 2.5s infinite;
  z-index: -1;
}

@keyframes pulse-lightgreen {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(29, 226, 207, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-orange {
  background: rgb(255, 137, 91);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(255, 137, 91);
  transform: scale(1);
  animation: pulse-orange 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 137, 91, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-red {
  background: rgb(253, 52, 110);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(253, 52, 110);
  transform: scale(1);
  animation: pulse-red 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(253, 52, 110, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-blue {
  background: rgb(15, 137, 255);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(15, 137, 255);
  transform: scale(1);
  animation: pulse-blue 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(15, 137, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-purple {
  background: rgb(96, 1, 211);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(96, 1, 211);
  transform: scale(1);
  animation: pulse-purple 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(96, 1, 211, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-green {
  background: rgb(12, 214, 138);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(12, 214, 138);
  transform: scale(1);
  animation: pulse-green 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(12, 214, 138, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-yellow {
  background: rgb(252, 220, 0);
  border-radius: 50%;
  margin: 16px;
  height: 16px;
  width: 16px;

  box-shadow: 0 0 0 0 rgb(252, 220, 0);
  transform: scale(1);
  animation: pulse-yellow 2.5s infinite;
  z-index: -1;

}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(252, 220, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 21px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blue-blur {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  transform: translateX(790px);
  overflow-x: visible;
}

.blue-blur-img {
  position: absolute;
}

.plusses {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
}

.plusses-img {
  position: absolute;
  top: 700px;
}

.icon-container {
  display: none;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 80px;
}

.icon {
  display: inline-flex;
  background: #FCDC00;
  box-shadow: -3.75px 11.25px 22.5px rgba(252, 220, 0, 0.301961);
  padding: 18px;
  border-radius: 50%;
}



@media screen and (max-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }


}

@media screen and (max-width: 778px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 2.2rem 0;

  }

  .icon-container {
    display: flex;
  }

  .card-container:hover {
    background: none;
    box-shadow: none;
    border-radius: 16px;
    transform: none;
  }

  .blue-blur {
    display: none !important;
  }

  .plusses {
    display: none;
  }
}