.logo {
  height: clamp(1.65rem, -2.75rem + 6.6vw, 2.65rem);
  width: clamp(3.125rem, -2.43rem + 11.5vw, 6.25rem);
}

nav.bar {
  padding: 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(243, 244, 246, 0.3);
  backdrop-filter: blur(8.5px);

}

ul.list {
  list-style: none;
  display: flex;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.85px;
  padding-inline-start: 0;
  column-gap: 20px;
}

ul.list a {
  text-decoration: none;
  color: var(--blackColor);
  font-size: clamp(0.5625rem, -0.0529rem + 1.2821vw, 0.8125rem);
  white-space: nowrap;
}

div.buttons {
  display: flex;
  align-items: center;
}

div.search {
  padding-right: 25px;
  margin-right: 25px;
  border-right: 2px solid #ABAFC7;

}

.right {
  white-space: nowrap;
  display: flex;
  column-gap: 13px;
}

.right>button {
  display: inline-block;
}

button.login {
  background: #4392F1;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  color: #fff;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.25rem + 0.5208333333333333vw, 0.875rem);

  line-height: 21px;
  text-align: center;
  letter-spacing: 0.747368px;
  transition: var(--transition);
  /* height: clamp(1.65rem, -2.75rem + 6.6667vw, 2.65rem); */
  padding: 10px 15px;
  cursor: pointer;
}

button.login:hover {
  box-shadow: -7.5px 22.5px 37.5px rgba(67, 146, 241, 0.3);
  transition: var(--transition);
}

button.demo {
  padding: 0;
  background: #141414;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.0096rem + 1.2821vw, 0.875rem);

  line-height: 21px;
  text-align: center;
  letter-spacing: 0.747368px;
  color: #FFFFFF;
  transition: var(--transition);
  /* height: clamp(1.65rem, -2.75rem + 6.6667vw, 2.65rem); */
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.demo:hover {
  box-shadow: -7.5px 22.5px 37.5px rgba(0, 0, 0, 0.3);
  transition: var(--transition);
}

button.lang {
  padding: 0;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.0096rem + 1.2821vw, 0.875rem);
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #28303F;
  transition: var(--transition);
  padding: 10px 15px;

}

button.lang:hover {
  box-shadow: -7.5px 22.5px 37.5px rgba(255, 255, 255, 0.3);
  transition: var(--transition);
}

.dropdown-content {
  position: absolute;
  z-index: 1;
  transform: translateX(-30px);
  margin-top: 13px;
  background: #FFFFFF;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: clamp(9.375rem, -1.1819620253164551rem + 21.99367088607595vw, 16.0625rem);
  color: #70798B;
}

.dropdown-content>ul {
  list-style: none;
  padding-inline-start: 0;
  padding: clamp(0.875rem, -0.49208860759493667rem + 2.848101265822785vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: clamp(0.75rem, -0.46518987341772156rem + 2.5316455696202533vw, 1.75rem);
  font-size: clamp(0.5rem, 0.044303797468354444rem + 0.949367088607595vw, 0.875rem);
}

.dropdown-content>ul>li>a {
  color: #70798B;
}

.dropdown-content>ul>li>a:hover {
  color: #222222;
}


.lang-dropdown-content {
  position: absolute;
  z-index: 1;
  margin-top: px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  box-shadow: -7.5px 22.5px 37.5px rgba(255, 255, 255, 0.3);
  width: 14px;
  display: flex;
  justify-content: center;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.0096rem + 1.2821vw, 0.875rem);

  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #28303F;
}

.lang-dropdown-content>ul {
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: clamp(0.625rem, 0.0096rem + 1.2821vw, 0.875rem);


}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}


.dropdown {
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: perspective(1000px);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.625rem, 0.0096rem + 1.2821vw, 0.875rem);
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #28303F;
  /* height: clamp(1.65rem, -2.75rem + 6.6667vw, 2.65rem); */
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  background-color: #fff;
  list-style-type: none;
  margin: 0;
  padding: 0;
  left: 0;
  opacity: 0;
  text-align: center;
  top: 0;
  visibility: hidden;
  z-index: -99999;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #70798B;
  margin-top: 2px;
}


.dropdown-menu li {
  color: var(--secondaryColor);
  width: 100%;
  text-decoration: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  padding: 7px 0;

}

.dropdown-menu li:hover {
  color: var(--blackColor);

}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  width: 100%;
  -webkit-transition: all var(--transition);
  transition: all var(--transition);
}

/**/

.dropdown1 {
  color: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  transform: perspective(1000px);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  font-size: clamp(0.5rem, 0.25rem + 0.5208333333333333vw, 0.875rem);
  color: var(--blackColor);
}


.dropdown-menu1 {
  background-color: #fff;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  opacity: 0;
  text-align: center;
  top: 0;
  visibility: hidden;
  z-index: -99999;
  /**/
  list-style: none;
  padding-inline-start: 0;
  padding: clamp(0.875rem, -0.49208860759493667rem + 2.848101265822785vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: clamp(0.75rem, -0.46518987341772156rem + 2.5316455696202533vw, 1.75rem);
  font-size: clamp(0.5rem, 0.044303797468354444rem + 0.949367088607595vw, 0.875rem);
  margin-top: 18px;
  border-radius: 16px;
}



.dropdown-menu1 li a {
  color: var(--subTitleColor);
  display: inline-block;
  width: 100%;
  text-decoration: none;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.dropdown-menu1 a:hover {
  color: var(--blackColor);
}

.dropdown1:hover .dropdown-menu1 {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .4s;
  transition: all var(--transition);
}

.arrow {
  display: inline-block;
  transition: var(--transition);

}

.dropdown1:hover .arrow {
  transform: rotate(180deg);
  transition: var(--transition);
}

/* .search {
  display: flex;
  align-items: center;
  position: relative;
}



.search input {
  width: 14px;
}

.search label:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("../../../assets/icons/search-icon.svg") center / contain no-repeat;
}

.search input:focus {
  position: absolute;
  transition: var(--transition);
  width: 200px;
  transform: translateX(-200px);
}

.search label:focus {}
 */

.hamburger {
  display: none;
}

@media screen and (max-width: 1050px) {
  .demo {
    padding: 5px 10px;
    width: 50px;
  }

  .dropdown {
    padding: 0px;
    width: 30px;
  }

  nav.bar {
    padding: 2.2rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  nav.bar {
    padding: 2.2rem 0rem;
  }

  .logo {
    height: unset;
    width: unset;
  }

  ul.list {
    display: none;
  }

  div.buttons {
    display: none;
  }

  .hamburger {
    display: block;
  }


}