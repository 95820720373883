.container {
  display: flex;
  align-items: center;
  padding-top: 138px;
  display: flex;
  text-align: right;
  justify-content: center;
  position: relative;
}

.container>div {
  margin: 0 80px;
}

.texts h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: clamp(1.875rem, 1.0416666666666665rem + 1.7361111111111112vw, 2.34375rem);
  line-height: clamp(2.5rem, 0.7222222222222223rem + 3.7037037037037033vw, 3.5rem);
  letter-spacing: -1.5px;
  color: #030314;
}

.texts p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: var(--subTitleColor);
  width: clamp(15.625rem, 10.069444444444445rem + 11.574074074074073vw, 18.75rem);
  margin: 30px 0;
  margin-right: 40px;
}

.texts a {
  text-decoration: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--blackColor);
  display: inline-flex;
  justify-content: flex-end;
  transition: var(--transition);
}

.texts a:hover {
  color: var(--primaryColor);
  transition: var(--transition);

}

.play-btn {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.container .imgs .panel {
  width: clamp(26.875rem, 0.875rem + 54.166666666666664vw, 41.5rem);
}

.imgs {
  position: relative;
}

.back {
  position: absolute;
  z-index: -1;
  top: -50px;
  left: -350px;
}

.back1 {
  position: absolute;
  z-index: -1;
  top: -140px;
  left: 460px;
}

.blue-blur {
  position: relative;
  z-index: -1;

}

.blue-blur-img {
  position: absolute;
  top: -610px;
  left: -750px;
}

.flex {
  display: flex;
}

.red-line {
  width: clamp(2.5rem, 1.6666666666666665rem + 1.7361111111111112vw, 3.75rem);
  height: 3px;
  background-color: #FD346E;
  border-radius: 3px;
  margin-bottom: 16px;
  margin-left: auto;
}

.red-line-vertical {
  background-color: #FD346E;
  border-radius: 3px;
  width: 3px;
  height: clamp(2.5rem, 1.6666666666666665rem + 1.7361111111111112vw, 3.75rem);
  margin-top: 35px;

}

.play-btn-m {
  display: none;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 60px 0;
  }

  .container>div {
    margin: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
    text-align: center;
  }

  .container p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .container a {

    display: inline;
    justify-content: center;

  }

  .red-line {
    margin: auto;
    margin-bottom: 24px;
  }

  .red-line-vertical {
    display: none;
  }

  .container .imgs .panel {
    margin-top: 50px;
    width: 100%;
  }



  .play-btn {
    display: none;
  }

  .play-btn-m {
    display: block;
    position: absolute;
    /* top: -50px; */
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .blue-blur {
    display: none !important;
  }
}