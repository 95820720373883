html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(243, 244, 246);
  position: relative;
}

a {
  cursor: pointer;
}