.logo {
  height: clamp(1.65rem, -2.75rem + 6.6vw, 2.65rem);
  width: clamp(3.125rem, -2.43rem + 11.5vw, 6.25rem);
}

.footer {
  padding: 3.2rem;
  padding-bottom: 0;
}

.footer-upper {
  padding-top: 4.5rem;
  display: flex;
  justify-content: space-between;
}

.footer-lower {
  display: flex;
  justify-content: space-between;
  padding: 3.375rem 0;
}

.footer-lower>p {
  margin: 0;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.4375rem, 0.057753164556962rem + 0.7911392405063291vw, 0.75rem);
  line-height: 15px;
  color: var(--subTitleColor);
  mix-blend-mode: normal;
  opacity: 0.75;
}

.footer-p {
  margin-top: 2rem;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.5rem, 0.044303797468354444rem + 0.949367088607595vw, 0.875rem);
  line-height: 25px;
  color: var(--subTitleColor);
  width: clamp(15.625rem, 8.03006329113924rem + 15.822784810126583vw, 21.875rem);
}

.w-20 h4 {
  margin-block-start: 0;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: clamp(0.5625rem, 0.10680379746835444rem + 0.949367088607595vw, 0.9375rem);
  line-height: 22px;
  color: var(--colorBlack);
}

.socials {
  display: flex;
  align-items: center;
  column-gap: 1.85rem;
}

.w-20>ul {
  list-style: none;
  padding-inline-start: 0;
}

.w-20>ul>li>a {
  text-decoration: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.4375rem, 0.057753164556962rem + 0.7911392405063291vw, 0.75rem);
  line-height: 19px;
  color: var(--subTitleColor);
}

.w-20>ul>li>a:hover {
  font-weight: 600;
  color: var(--primaryColor)
}


.footer hr {
  border-color: #D5D5D545;

}

.socials {
  margin-top: 15px;
}

.socials a svg {
  /* filter: invert(53%) sepia(10%) saturate(638%) hue-rotate(182deg) brightness(86%) contrast(87%); */
  filter: invert(61%) sepia(54%) saturate(4968%) hue-rotate(192deg) brightness(100%) contrast(90%);
  transition: var(--transition);

}


.w-40 {
  width: 40%;
  transform: translateY(-69px);
}

.w-60 {
  width: 60%;
  display: flex;
}

.w-20 {
  width: 33%;
}



@media screen and (max-width: 768px) {

  .w-40 {
    width: 100%;
  }

  .w-60 {
    width: 100%;
    flex-direction: column;
  }

  .w-20 {
    width: 100%;
    padding: 30px 0;
    border-bottom: 1px solid #D5D5D5;
  }

  .w-20:last-child {
    border: none;
  }

  .footer {
    padding: 3.2rem 0;
    padding-bottom: 0;
  }

  .footer-upper {
    flex-direction: column;
  }

  .logo {
    height: unset;
    width: unset;
  }

  .footer-p {
    font-size: 14px;
    line-height: 25px;
    color: var(--subTitleColor);
    width: 100%;
  }

  .socials {
    display: none;
  }

  .w-20 h4 {
    font-size: 18px;
    margin-bottom: 26px;
  }

  .w-20>ul>li {
    margin-bottom: 8px;
  }

  .w-20>ul>li>a {
    font-size: 14px;
  }

  .footer-lower {
    padding: 30px 0;
  }

  .footer-lower>p {
    font-size: 12px;
  }
}