.back {
  background-image: url('../../assets/images/application_background.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 444px;
  margin-top: 100px;
}

.inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.inner-content h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.4px;
  color: #FFFFFF;
}


.inner-content p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FBFBFB;
  margin: 10px 0;
}

.inner-content form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inner-content input {
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;

  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #C1C1C1;
  margin: 7px 0;
  min-width: 300px;
  padding-left: 40px;
}

.inner-content button {
  background: #D5D5D5;

  border-radius: 8px;
  border-width: 0;

  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  margin: 7px 0;
  cursor: pointer;
  transition: var(--transition);
  cursor: not-allowed;

}


.inner-content label {
  position: relative;
  display: block;
}

.inner-content .user:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("../../assets/icons/user-icon.svg") center / contain no-repeat;
}

.inner-content .phone:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("../../assets/icons/phone-icon.svg") center / contain no-repeat;
}

.inner-content .message:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("../../assets/icons/message-icon.svg") center / contain no-repeat;
}

.active-button {
  background: #0D6EFD !important;
  cursor: pointer !important;
}

.active-button:hover {
  background: #84BCFF !important;
}

.inner-content input:focus {
  outline: 2px solid var(--primaryColor);
}

@media screen and (max-width: 778px) {
  .inner-content {
    flex-direction: column;
    padding: 2.2rem 0;
  }

  .inner-content form {
    gap: 15px;
    margin-top: 15px;
  }

  .inner-content h3 {
    text-align: center;
  }

  .inner-content p {
    text-align: center;
    margin-top: 10px;
  }

  .back {
    background-image: url('../../assets/images/application_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    margin: 0 1rem;
    border-radius: 8px;
  }

  .inner-content input {
    min-width: 300px;
  }
}

@media screen and (max-width: 420px) {
  .inner-content input {
    min-width: 200px;
  }
}