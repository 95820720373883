.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  padding: 2.2rem;
}

.card-container {
  padding: 44px 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-back-green {
  background: #1DE2CF;
  padding: 20px;
  box-shadow: -3.75px 11.25px 22.5px rgba(29, 226, 207, 0.301961);
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-red {
  background: #FD346E;
  box-shadow: -3.75px 11.25px 22.5px rgba(253, 52, 110, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-yellow {
  background: #FCDC00;
  box-shadow: -3.75px 11.25px 22.5px rgba(252, 220, 0, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}


.card-container h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 22.5px;
  line-height: 34px;
  letter-spacing: -0.75px;
  color: #030314;
  margin: 20px 0;
  text-align: center;
}

.card-container p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: var(--subTitleColor);
}

.blue-blur {
  position: relative;
  z-index: -1;

}

.blue-blur-img {
  position: absolute;
  top: -610px;
  left: -750px;
}

.rings {
  position: relative;
  z-index: -1;
}

.rings-img {
  position: absolute;
  transform: rotate(-120deg);
  top: 300px;
}

.icon-container {
  display: none;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 80px;
}

.icon {
  display: inline-flex;
  background: #1DE2CF;
  box-shadow: -3.75px 11.25px 22.5px rgba(29, 226, 207, 0.301961);
  padding: 18px 16px;
  border-radius: 50%;
}


@media screen and (max-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 2.2rem 0;

  }

  .icon-container {
    display: flex;
  }

  .blue-blur {
    display: none !important;
  }
}