.back {
  position: absolute;
  z-index: -1;
  top: 0;
  display: flex;
  justify-content: center;
}

.back .back-img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: clamp(26.875rem, 0.09722222222222143rem + 55.78703703703704vw, 41.9375rem);
}

.back .divider {
  position: absolute;
  /* top: -120px; */
  left: -725px;
  z-index: -1;
}

.back .divider1 {
  position: absolute;
  top: -50px;
  left: -57px;
  z-index: -1;
}

.back .divider2 {
  position: absolute;
  left: -25px;
  z-index: -1;
}

.back .divider3 {
  position: absolute;
  top: 200px;
  left: -850px;
  z-index: -1;
}

.home-section {
  display: flex;
  justify-content: flex-end;
}

.jumbo {
  padding-left: 2.2rem;
  width: clamp(25rem, 9.555555555555557rem + 32.175925925925924vw, 33.6875rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 130px;
}

.jumbo h1 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: clamp(1.875rem, -1.4583333333333335rem + 6.944444444444445vw, 3.75rem);
  line-height: clamp(2.5rem, -0.8333333333333335rem + 6.944444444444445vw, 4.375rem);
  letter-spacing: -1.5px;
  color: #141414;
}

.jumbo p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.875rem, 0.20833333333333337rem + 1.3888888888888888vw, 1.25rem);
  line-height: clamp(1.5rem, 0.38888888888888884rem + 2.314814814814815vw, 2.125rem);
  color: #696969;
  margin: 30px 0;
}

h1>span {
  color: #4392F1;
}

.stats {
  display: flex;
  column-gap: 80px;
}

.stats h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  letter-spacing: -0.403261px;
  color: #2C2C2C;
}

.stats h3 span {
  color: var(--primaryColor);
}

.buttons {
  display: flex;
  gap: 20px;
}

button.call-up {
  padding: 0;
  background: var(--primaryColor);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  color: #fff;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.044303797468354444rem + 0.949367088607595vw, 0.875rem);
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.747368px;
  transition: var(--transition);
  padding: 10px
}

button.call-up:hover {
  box-shadow: -7.5px 22.5px 37.5px rgba(67, 146, 241, 0.3);
  transition: var(--transition);
}

button.demo {
  padding: 0;
  background: transparent;
  border: 1px solid var(--primaryColor);
  border-radius: 8px;
  color: var(--primaryColor);
  background: #fff;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.5rem, 0.044303797468354444rem + 0.949367088607595vw, 0.875rem);
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.747368px;
  transition: var(--transition);
  padding: 10px
}

button.demo:hover {
  box-shadow: -7.5px 22.5px 37.5px rgba(67, 146, 241, 0.3);
  transition: var(--transition);
}

.red-line {
  width: clamp(2.5rem, 1.6666666666666665rem + 1.7361111111111112vw, 3.75rem);
  height: 3px;
  background-color: #FD346E;
  border-radius: 3px;
  margin-bottom: 16px;
}

.decor {
  position: relative;
  z-index: -1;
}

.dots-left {
  position: absolute;
  top: 40px;
}

.decor1 {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
}

.dots-right {
  position: absolute;
  /* top: 577px; */
  transform: translateX(0px);
}

.jumbo-back {
  display: none;
}



@media screen and (max-width: 1200px) {
  .buttons {
    column-gap: 10px;
  }
}

@media screen and (max-width: 971px) {
  .back .divider1 {
    left: -120px;
  }
}

@media screen and (max-width: 768px) {

  .jumbo-back {
    width: 100%;
    display: block;
  }

  .jumbo-back .back-img {
    width: 100%;
  }

  .home-section .back-img {
    display: none;
  }

  .back {
    position: unset;
    display: block;
    width: 100%;
  }

  .back .back-img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    /* width: clamp(26.875rem, 0.09722222222222143rem + 55.78703703703704vw, 41.9375rem); */
    width: 100%;

  }

  .back .divider {
    position: absolute;
    /* top: -120px; */
    left: -650px;
    z-index: -1;
  }

  .back .divider1 {
    position: absolute;
    top: 100px !important;
    left: 0px;
    z-index: -1;
    /* display: none; */
  }

  .back .divider2 {
    position: absolute;
    left: 150px;
    z-index: -1;
  }

  .back .divider3 {
    position: absolute;
    top: 150px;
    left: -850px;
    z-index: -1;
  }

  .back .divider1 {
    display: none;
  }

  .dots-right {
    display: none;
  }

  .red-line {
    display: none;
  }

  .jumbo {
    padding: 0;
    width: auto;
    margin-top: 30px;
  }

  .jumbo h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
    letter-spacing: -0.946197px;
  }

  .jumbo p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #696969;

  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  .buttons button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.747368px;
  }

  button.demo:hover {
    box-shadow: none;
  }

  button.call-up:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 450px) {

  .back .divider {
    position: absolute;
    left: -690px !important;
    z-index: -1;
  }


  .back .divider2 {
    position: absolute;
    left: 50px !important;
    z-index: -1;
  }

  .back .divider3 {
    position: absolute;
    top: 150px;
    left: -850px;
    z-index: -1;
  }
}