.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.56);
  z-index: 2;
  backdrop-filter: blur(10px);
}

.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.container .popup {
  position: fixed;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45.166px 31.8819px;
  gap: 25.2px;
  width: 335px;
  background: #FFFFFF;
  border-radius: 17.2694px;
  z-index: 2;
}

.popup h4 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--blackColor);
}

.popup form {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* row-gap: 15px; */
}

.popup form label {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: var(--blackColor);
}

.popup form input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 6.64px;
  background: #F3F4F6;
  border-radius: 7.97048px;
  border: none;

  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 400;
  font-size: 11.9557px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: var(--secondaryColor);
  padding: 12px;
  margin-bottom: 19px;
}

.rmb-me-frg-pass {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.terms {
  display: flex;
}

.terms input {
  margin-top: 7px;
  margin-right: 7px;
}

.terms div {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: var(--blackColor);
}

.terms div a {
  text-decoration: none;
  color: var(--primaryColor);
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me span {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #222222;
}

.login-btn {
  padding: 12px 28px;
  gap: 8px;
  background: #4392F1;
  border-radius: 7.97048px;

  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.496407px;
  color: #FFFFFF;
  border: none;
}

.register {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--secondaryColor);
}

.register a {
  text-decoration: none;
  color: var(--primaryColor);
}

.popup input:focus {
  outline: 2px solid var(--primaryColor);
}

.terms input:focus {
  outline: unset;
}

@media screen and (max-width: 450px) {
  .popup {
    width: 70% !important;
  }
}