.container {
  padding: 2.2rem;
}


.partner-logo{
  margin: auto 35px;
}

.partners {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partners img {
  height: 50px;
  max-width: 240px;
  filter: invert(87%) sepia(3%) saturate(8%) hue-rotate(316deg) brightness(88%) contrast(101%);
  transition: 150ms;
}

.partners img:hover {
  filter: unset;
  transition: 150ms;
}

.waves {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.waves-img {
  position: absolute;
  top: -700px;
}

@media screen and (max-width: 778px) {
  .partners {
    flex-direction: column;
    gap: 100px;
    margin-top: 100px;
  }

  .container {
    padding: 2.2rem 0;
  }

  .partners img:hover {
    filter: invert(87%) sepia(3%) saturate(8%) hue-rotate(316deg) brightness(88%) contrast(101%);
  }
}