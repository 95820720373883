@font-face {
  font-family: Poppins;
  src: url('./Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url('./Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url('./Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('./Poppins-Bold.ttf');
  font-weight: 700;
}