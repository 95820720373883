.show-more-btn {
  display: none;
}


@media screen and (max-width: 768px) {

  .children-desktop {
    display: none;
  }

  .show-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 28px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.07), 0px 12px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: none;

    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.747368px;

    color: var(--blackColor);
  }
}