.card {
  background: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.07), 0px 12px 16px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  transition: var(--transition);
}

.card:hover {
  box-shadow: -15px 30px 60px rgba(22, 22, 22, 0.3);
  border-radius: 16px;
  transition: var(--transition);
  transform: translateY(-17px);
}

@media screen and (max-width: 768px) {
  .card:hover {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.07), 0px 12px 16px rgba(0, 0, 0, 0.04);
    transform: none;
  }
}