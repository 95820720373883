.title {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #081420;
  margin: 25px 0;
  margin-top: 100px;
}

.red-line {
  width: clamp(2.5rem, 1.6666666666666665rem + 1.7361111111111112vw, 3.75rem);
  height: 3px;
  background-color: #FD346E;
  border-radius: 3px;
  margin: auto;
}


@media screen and (max-width: 768px) {
  .title {
    margin-top: 0px;
  }

}