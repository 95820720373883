.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  padding: 2.2rem;
}

.card-grid>div:nth-child(3n-1) {
  transform: translateY(40px);
}

.card-grid>div:nth-child(3n-1):hover {
  transform: translateY(23px);
}

.card-container {
  padding: 44px 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-back-green {
  background: #1DE2CF;
  padding: 20px;
  box-shadow: -3.75px 11.25px 22.5px rgba(29, 226, 207, 0.301961);
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-red {
  background: #FD346E;
  box-shadow: -3.75px 11.25px 22.5px rgba(253, 52, 110, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-yellow {
  background: #FCDC00;
  box-shadow: -3.75px 11.25px 22.5px rgba(252, 220, 0, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-blue {
  background: #0F89FF;
  box-shadow: -3.75px 11.25px 22.5px rgba(15, 137, 255, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-brown {
  background: #FF895B;
  box-shadow: -3.75px 11.25px 22.5px rgba(255, 137, 91, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.icon-back-purple {
  background: #6001D3;
  box-shadow: -3.75px 11.25px 22.5px rgba(96, 1, 211, 0.301961);
  padding: 20px;
  border-radius: 50%;
  display: inline-flex;
}

.card-container h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 22.5px;
  line-height: 34px;
  letter-spacing: -0.75px;
  color: #030314;
  margin: 20px 0;
  text-align: center;
}

.card-container p {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: var(--subTitleColor);
}

.card-container a {
  text-decoration: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--blackColor);
  margin-top: 30px;
  transition: var(--transition);

}

.card-container a:hover {
  color: var(--primaryColor);
  transition: var(--transition);
}

.icon-container {
  display: none;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 80px;
}

.icon {
  display: inline-flex;
  background-color: #FD346E;
  box-shadow: -3.75px 11.25px 22.5px rgba(253, 52, 110, 0.301961);
  padding: 18px;
  border-radius: 50%;
}


@media screen and (max-width: 1100px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-grid>div:nth-child(3n-1) {
    transform: translateY(00px);
  }

  .card-grid>div:nth-child(3n-1):hover {
    transform: translateY(-17px);
  }
}

@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 2.2rem 0;
  }

  .card-container {
    padding: 20px 20px;
  }

  .icon-container {
    display: flex;
  }

  .card-grid>div:nth-child(3n-1):hover {
    transform: none;
  }

}